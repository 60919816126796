
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import adminApi from "../core/services/AdminApi";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
    name: "driveDetail",
    components: {},
    data() {
        return {
            document: "",
        };
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
    created() {
        adminApi.getDocument(this.$route.params.id).then((data: any) => {
            this.document = data.document;
        });
        setCurrentPageBreadcrumbs(this.t("pages.drive.titleDetail"), [
            {
                link: "/drive/",
                label: this.t("pages.drive.title"),
            },
        ]);
    },
});
